<div id="header" class="px-2 px-md-4">
  <div [routerLink]="RouteLocations.Dashboard.absoluteUrl"
       id="header-logo-container"
       class="px-2 px-md-4"
  >
    <app-logo height="30px" color="light"></app-logo>

    <div class="ms-1">
      <svg style="height: 35px; position: relative; bottom: -8px;" class="w-auto" fill="#fff"
           viewBox="166.555 182.438 91.365 53.925" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M 50.981 79.945 Q 50.981 79.945 51.032 79.738 Q 51.084 79.531 51.511 79.207 Q 51.938 78.883 52.159 78.76 Q 52.379 78.638 52.463 78.57 Q 52.068 80.604 54.705 80.604 Q 56.783 80.604 59.877 79.308 Q 62.066 78.414 62.97 77.476 Q 63.266 77.185 63.314 76.995 Q 63.361 76.805 63.322 76.693 L 54.566 74.615 Q 54.186 74.526 53.879 74.235 Q 53.309 73.744 53.409 73.342 Q 53.794 71.8 56.295 70.548 Q 59.049 69.185 63.266 68.492 Q 64.221 68.336 64.674 68.314 Q 64.797 68.358 65.068 68.392 Q 65.339 68.425 65.612 68.492 Q 66.266 68.649 66.048 69.073 Q 65.859 69.833 62.843 70.28 Q 58.949 70.839 57.927 71.219 Q 56.905 71.599 56.391 72.001 Q 55.878 72.403 55.716 72.962 Q 55.688 73.073 56.169 73.207 Q 56.649 73.342 57.317 73.531 Q 57.985 73.721 59.046 74.034 Q 61.628 74.794 65.36 75.956 Q 65.461 76.448 65.355 76.872 Q 65.06 78.057 63.402 79.151 Q 61.531 80.38 58.548 81.23 Q 56.074 81.922 54.711 81.922 Q 53.571 81.922 52.644 81.52 Q 51.716 81.118 51.297 80.738 Q 50.878 80.358 50.981 79.945 Z"
          transform="matrix(1.443793, 0, 0, 1.529576, 100.24102, 94.074661)" />
        <path
          d="M 76.04 81.587 Q 75.744 81.967 74.906 81.967 Q 74.068 81.967 74.219 81.364 Q 74.876 78.727 75.01 77.744 Q 75.349 75.576 74.74 75.42 Q 73.209 75.107 72.192 75.107 Q 71.176 75.107 70.363 75.721 Q 69.551 76.336 68.851 77.755 Q 68.15 79.174 67.353 81.744 L 65.633 81.744 Q 65.995 80.47 66.268 79.375 Q 66.541 78.28 66.872 76.772 Q 67.204 75.263 67.582 73.476 Q 67.983 71.42 68.337 70.001 Q 68.691 68.582 69.165 67.398 Q 69.494 66.973 69.919 66.973 Q 70.433 66.973 70.631 67.342 Q 70.83 67.71 70.78 68.001 L 69.749 71.688 Q 69.231 73.766 69.175 74.794 Q 71.521 73.453 73.666 73.453 Q 74.359 73.453 75.113 73.565 Q 76.572 74.436 76.84 75.42 Q 76.958 75.934 76.88 76.515 Q 76.802 77.096 76.641 77.833 Q 76.14 80.38 76.04 81.587 Z"
          transform="matrix(1.443793, 0, 0, 1.529576, 100.24102, 94.074661)" />
        <path
          d="M 78.408 77.654 Q 79.065 75.017 81.746 73.409 Q 83.873 72.135 86.488 71.867 Q 86.622 71.867 87.113 71.867 Q 87.605 71.867 88.359 72.157 Q 90.036 72.783 90.31 74.369 Q 90.422 74.995 90.314 75.431 Q 90.205 75.867 90.138 76.045 Q 89.442 78.839 86.918 80.448 Q 84.584 81.922 81.634 81.922 Q 79.511 81.922 78.673 80.939 Q 77.834 79.956 78.408 77.654 Z M 79.816 78.191 Q 79.47 79.576 80.085 80.068 Q 80.555 80.425 81.873 80.425 Q 83.192 80.425 84.247 80.224 Q 85.303 80.023 86.208 79.531 Q 88.151 78.459 88.714 76.202 Q 89.048 74.861 88.545 74.012 Q 87.991 73.096 86.717 73.096 Q 85.807 73.252 84.88 73.431 Q 83.952 73.61 83.042 74.124 Q 81.071 75.219 79.816 78.191 Z"
          transform="matrix(1.443793, 0, 0, 1.529576, 100.24102, 94.074661)" />
        <path
          d="M 94.04 72.939 Q 97.341 71.889 99.553 71.889 Q 102.369 71.889 102.94 73.364 Q 103.191 74.057 102.991 74.861 Q 102.712 75.978 101.713 77.163 Q 100.714 78.347 99.312 79.308 Q 96.057 81.52 92.057 82.056 Q 91.996 82.302 91.871 82.85 Q 91.745 83.397 91.589 84.068 Q 91.434 84.738 91.255 85.498 Q 91.077 86.257 90.927 86.95 Q 90.565 88.671 90.537 88.917 Q 90.509 89.162 90.47 89.319 Q 90.331 89.877 89.904 90.157 Q 89.476 90.436 88.895 90.436 Q 88.784 90.436 88.672 90.436 L 92.794 72.47 Q 93.067 72.18 93.397 72.202 Q 93.727 72.224 93.889 72.381 Q 94.051 72.537 94.04 72.939 Z M 100.37 73.721 Q 99.727 73.342 98.889 73.342 Q 98.051 73.342 97.186 73.498 Q 95.219 73.855 94.019 75.085 Q 93.472 75.665 93.26 76.515 Q 93.238 76.604 93.216 76.693 L 92.252 80.559 Q 97.111 79.531 99.49 77.699 Q 100.869 76.649 101.148 75.531 Q 101.443 74.347 100.37 73.721 Z"
          transform="matrix(1.443793, 0, 0, 1.529576, 100.24102, 94.074661)" />
      </svg>
    </div>
  </div>

  <div id="header-content" class="flex-grow-1 flex-row-center justify-content-end">
    <div class="me-3">
      <app-balance-indicator />
    </div>

    <app-user-menu />
  </div>
</div>
