<ng-container *ngIf="session">
  <div [matMenuTriggerFor]="menu" data-test-id="user-menu"
       style="height: 30px; width: 30px"
       class="cursor-pointer content-center text-white rounded-circle border-white user-select-none">
    <fa-icon icon="user"></fa-icon>
  </div>
  <mat-menu #menu="matMenu">
    <a mat-menu-item [routerLink]="RouteLocations.Dashboard.children.MyCoupons.absoluteUrl">
      {{ 'Core.UserMenu.MyCoupons' | translate }}
    </a>
    <a mat-menu-item [href]="'mailto:' + supportEmailAddress">
      {{ 'Core.UserMenu.Support' | translate }}
    </a>
    <button mat-menu-item (click)="logoutClickAsync()">
      {{ 'Core.UserMenu.Logout' | translate }}
    </button>
  </mat-menu>
</ng-container>

<ng-container *ngIf="!session">
  <button mat-button color="primary" (click)="goToLoginAsync()" class="d-none d-md-inline-block bg-transparent py-3 px-4">
    <span class="fw-bold text-white">{{ 'Core.UserMenu.Login' | translate }}</span>
  </button>
  <button mat-stroked-button
          color="primary"
          (click)="goToRegistrationAsync()"
          class="ms-lg-3 py-2 py-md-3 px-4 fw-bold border-white">
    <span class="text-white text-nowrap">{{ 'Core.UserMenu.Register' | translate }}</span>
  </button>
</ng-container>
