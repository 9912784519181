<div class="app-container">
  <div class="header-container">
    <app-header></app-header>
  </div>
  <div class="main-container">
    <router-outlet></router-outlet>
  </div>
  <div class="footer-container">
    <app-footer></app-footer>
  </div>
</div>
