import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { ShopAppState } from '../../../shop-app.state';
import { Observable } from 'rxjs';
import { UserSessionWithShopAccount } from '../../../../../../common/dto/user-session.dto';
import { AppCommonModule } from '../../../common/common.module';
import { faCoins } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-balance-indicator',
  templateUrl: './balance-indicator.component.html',
  styleUrls: ['./balance-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AppCommonModule,
  ],
})
export class BalanceIndicatorComponent {
  @Select(ShopAppState.session)
  public session$!: Observable<UserSessionWithShopAccount>;
  protected readonly faCoins = faCoins;
}
