import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ShopRouteLocations } from '../../common/routing/route-locations.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  constructor() {}

  public RouteLocations = ShopRouteLocations;
}
