<footer class="mt-4 text-center text-md-start">
  <div class="row">
    <div class="col-12 col-md-4">
      <app-logo color="light"></app-logo>

      <div class="mt-3">
        {{ 'Core.Footer.OneLiner' | translate }}
      </div>

      <div class="mt-5">
        <h4 class="text-uppercase">{{ 'Core.Footer.GetInTouch' | translate }}</h4>
        <div class="mt-1">
          <a href="mailto:{{ supportEmail }}" target="_blank">{{ supportEmail }}</a>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4">
      <div class="mt-5 mt-md-0">
        <h4 class="text-uppercase">{{ 'Core.Footer.FollowUs' | translate }}</h4>

        <div class="content-center justify-content-md-start">
          <a class="social-button instagram" href="https://www.instagram.com/joinohana/" target="_blank">
            <fa-icon [icon]="['fab', 'instagram']" size="lg"></fa-icon>
          </a>
          <a class="social-button linked-in" href="https://www.linkedin.com/company/join-ohana/about/" target="_blank">
            <fa-icon [icon]="['fab', 'linkedin']" size="lg"></fa-icon>
          </a>
        </div>
      </div>

      <div class="mt-5">
        <div>
          <a [href]="privacyUrl" target="_blank">{{ 'Common.PrivacyPolicy' | translate }}</a>
        </div>
        <div class="mt-2">
          <a [href]="termsUrl" target="_blank">{{ 'Common.TermsAndConditions' | translate }}</a>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4">
      <a href="https://www.esgmark.co.uk/?utm_source=Ohana_footer&amp;utm_medium=membership_charter&amp;utm_campaign=Ohana_partnership" target="_blank">
        <img ngSrc="https://uploads-ssl.webflow.com/62f5026a49b546fdbd5c6f24/63888f8abaaa356bdc124726_Untitled%20(400%20%C3%97%20400px).png" priority="false" [width]="253" [height]="253" alt="ESGMark Member">
      </a>
    </div>
  </div>

  <div class="mt-3"><hr/></div>

  <div class="mt-3 content-center">

  </div>

  <div class="mt-1 content-center fs-8 text-muted">
    <div>v{{ version }}</div>

    <ng-container *ngIf="!isProduction">
      <div class="d-none d-lg-block mx-1">|</div>
      <div class="text-danger">----- DEVELOPMENT -----</div>
    </ng-container>
  </div>
</footer>
