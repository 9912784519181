import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ENVIRONMENT } from '../../common/services/injection-tokens';
import { Environment } from '../../../environments/environment.interface';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {

  constructor(
    @Inject(ENVIRONMENT) private _environment: Environment,
  ) { }

  protected year = new Date().getFullYear();

  protected supportEmail = this._environment.links.supportEmail;

  protected websiteUrl = this._environment.links.websiteUrl;

  protected termsUrl = this._environment.links.termsUrl;
  protected privacyUrl = this._environment.links.privacyUrl;

  protected version = this._environment.version;
  protected isProduction = this._environment.production;
}
