import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShopRouteLocations } from './common/routing/route-locations.enum';
import { NotFoundComponent } from './not-found/not-found.component';
import { LoggedInRouteGuard } from './common/routing/route-guards/logged-in.guard';

const redirectRules: { path: string; redirectTo: string; }[] = [];

const routes: Routes = [
  {
    path: ShopRouteLocations.Dashboard.relativeUrl,
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: ShopRouteLocations.Login.relativeUrl,
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: ShopRouteLocations.ClaimCredit.relativeUrl,
    loadChildren: () => import('./claim-credit/claim-credit.module').then((m) => m.ClaimCreditModule),
    canActivate: [
      LoggedInRouteGuard,
    ],
  },
  {
    path: ShopRouteLocations.NotFound.relativeUrl,
    component: NotFoundComponent,
  },
  {
    path: ShopRouteLocations.Maintenance.relativeUrl,
    loadChildren: () => import('./maintenance/maintenance.module').then((m) => m.MaintenanceModule),
  },
  ...redirectRules.map(_ => {
    if (_.path.startsWith('/')) {
      _.path = _.path.slice(1);
    }

    return _;
  }),
  {
    path: '**',
    redirectTo: ShopRouteLocations.Dashboard.relativeUrl,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
