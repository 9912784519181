import { NgModule } from '@angular/core';
import { AppCommonModule } from '../common/common.module';
import { HeaderComponent } from './header/header.component';
import { UserMenuComponent } from './header/user-menu/user-menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBars, faCaretDown, faUser } from '@fortawesome/free-solid-svg-icons';
import { MatButtonModule } from '@angular/material/button';
import { FooterComponent } from './footer/footer.component';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { BalanceIndicatorComponent } from './header/balance-indicator/balance-indicator.component';

@NgModule({
  declarations: [
    HeaderComponent,
    UserMenuComponent,
    FooterComponent,
  ],
  imports: [
    AppCommonModule,

    MatButtonModule,
    MatMenuModule,

    BalanceIndicatorComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
  ],
})
export class CoreModule {
  constructor(faLibrary: FaIconLibrary) {
    faLibrary.addIcons(
      faCaretDown,
      faBars,
      faInstagram,
      faLinkedin,
      faUser,
    );
  }
}
